import { useState, useEffect } from 'react';
import QsrOrdersService from '@/services/qsrOrders';
import { accessTokenKey } from '@/config/axios';

export const useAgentConnection = (restaurantId: string | undefined) => {
    const [isDisconnected, setIsDisconnected] = useState(false);

    useEffect(() => {
        if (!restaurantId || !localStorage.getItem(accessTokenKey)) {
            return undefined;
        }

        const qsrService = QsrOrdersService.getInstance();

        const checkAgentStatus = async () => {
            try {
                const response = await qsrService.getAgentConnectionStatus(restaurantId);
                setIsDisconnected(!response.data.online);
            } catch (error) {
                console.log(`API Error for restaurant: ${restaurantId}`, error);
            }
        };

        checkAgentStatus();
        const intervalId = setInterval(checkAgentStatus, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, [restaurantId]);

    return isDisconnected;
};
