import { useMemo, memo } from 'react';
import { IQsrOrder, OrderPaymentStatusEnum } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';
import { IRestaurant, QsrCardViewIndicatorEnum } from '@/views/OrdersTableView/types';
import { Voucher } from '@/components/InvoiceTable/types';
import { canDisplay } from '@/views/QsrOrders/utils';
import SellIcon from '@mui/icons-material/Sell';

import styles from './index.module.scss';

interface IProps {
    restaurant?: IRestaurant | null;
    order: IQsrOrder;
    isTableView?: boolean;
}

const StatusItem: React.FC<{ icon: React.ReactNode; label: string }> = ({ icon, label }) => (
    <div className={styles.newStatus}>
        {icon}
        {label}
    </div>
);

const QsrPaymentStatus = ({ order, restaurant, isTableView }: IProps) => {
    const { t } = useTranslation('common');
    const { vouchers = [], loyalties = [] } = order.paymentResponse || {};

    const canShow = useMemo(() => canDisplay(restaurant), [restaurant]);

    const [style, text] = useMemo(() => {
        switch (order.paymentStatus) {
            case OrderPaymentStatusEnum.Success:
                return [styles.success, t('paid')];
            default:
            case OrderPaymentStatusEnum.Failed:
            case OrderPaymentStatusEnum.Refunded:
            case OrderPaymentStatusEnum.Pending:
                return [styles.notPaid, t('not paid')];
        }
    }, [order.paymentStatus]);

    if (!order || !canShow(QsrCardViewIndicatorEnum.Payment)) {
        return null;
    }

    return (
        <div className={classNames(styles.main, { [styles.tableView]: isTableView }, style)}>
            {vouchers.map((_: Voucher, index: number) => (
                <StatusItem key={index} icon={<SellIcon sx={{ fontSize: 14 }} />} label="voucher" />
            ))}
            {loyalties.map((_: any, index: number) => (
                <StatusItem key={index} icon={<SellIcon sx={{ fontSize: 14 }} />} label="loyalty" />
            ))}
            <div className={styles.status}>{text}</div>
        </div>
    );
};

export default memo(QsrPaymentStatus);
