import { useState, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import TransactionService from '@/services/transaction';
import { useTranslation } from '@/hooks/translations';
import { IPaymentDetailsResponse } from '@/components/InvoiceTable/types';
import type { IPayment } from '@/views/Payments';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    initReceipt?: IPaymentDetailsResponse | null;
    paymentRecordId?: string;
    payment?: IPayment | null;
    vendorId?: string;
    preview?: boolean;
    innerRef?: any;
}

export default function ReceiptV3({ initReceipt, payment, paymentRecordId, vendorId, preview, innerRef }: IProps) {
    const transactionService = TransactionService.getInstance();
    const { t } = useTranslation('common');
    const [receipt, setReceipt] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);

    console.log(receipt);

    useEffect(() => {
        if (!vendorId || (!payment && !initReceipt) || loading || hasFetched.current) {
            return;
        }

        hasFetched.current = true;

        const transactionId = initReceipt?.dsi || payment?.transactionId || '';
        const prId = paymentRecordId || payment?.paymentRecordId || '';

        setLoading(true);
        transactionService
            .getReceiptDataV2(vendorId, transactionId, prId)
            .then((html) => {
                setReceipt(html);
            })
            .catch(console.log)
            .finally(() => {
                setLoading(false);
            });
    }, [vendorId, payment]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    height: 200,
                }}
            >
                <CircularProgress size={24} />
                <Typography>{t('Loading receipt...')}</Typography>
            </Box>
        );
    }
    if (!receipt) return null;

    return (
        <div
            className={classNames(styles.receipt, { [styles.visible]: preview })}
            style={{ display: 'block' }}
            ref={innerRef}
            dangerouslySetInnerHTML={{ __html: receipt }}
        />
    );
}
