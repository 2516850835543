import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'next-export-i18n';

interface ConnectionAlertProps {
    onClose: () => void;
}

export const AgentConnectionStatusAlert = ({ onClose }: ConnectionAlertProps) => {
    const { t } = useTranslation('common');

    return (
        <Alert
            severity="error"
            action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
        >
            {t('Agent connection is lost')}
        </Alert>
    );
};
