import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IQsrOrder } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import { Tooltip } from '@mui/material';
import { getRelativeTime } from '@/services/utils/k_time';
import QsrOrdersService from '@/services/qsrOrders';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { IPanelUser } from '@/services/menu/types';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
    restaurant?: IRestaurant | null;
}

const QsrOrderCreateStatus = ({ order, restaurant }: IProps) => {
    const qsrOrdersService = QsrOrdersService.getInstance();
    const { t } = useTranslation('common');
    const [userMap, setUserMap] = useState<Record<string, IPanelUser>>({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const getPanelUserName = useCallback((user: IPanelUser | undefined) => {
        if (!user) {
            return t('Unknown');
        }

        return `${user.first_name} ${user.last_name} (${user.email})`;
    }, []);

    useEffect(() => {
        if (!restaurant?.id || !order.panelUserId || loading) {
            return;
        }

        setLoading(true);
        qsrOrdersService
            .getPanelUserMap(restaurant.id, { ids: [order.panelUserId] })
            .then((res) => {
                setUserMap(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [restaurant, order]);

    const tooltipTitle = useMemo(() => {
        if (!order) {
            return '';
        }

        return t('Created by {{name}}, {{{date}}}', {
            name: order.panelUserId ? getPanelUserName(userMap[order.panelUserId]) : t('Unknown'),
            date: order.createdAt ? getRelativeTime(order.createdAt) : t('Unknown'),
        });
    }, [order, userMap]);

    return (
        <div className={styles.main}>
            <Tooltip
                title={tooltipTitle}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <div
                    className={styles.status}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    {t('by panel')}
                </div>
            </Tooltip>
        </div>
    );
};

export default memo(QsrOrderCreateStatus);
