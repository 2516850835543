import { createContext, ReactNode, useState, useEffect, useContext, useCallback } from 'react';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { accessTokenKey, chosenRestaurantIdKey, restaurantDataKey, selectedBrandOrRestaurantKey } from '@/config/axios';
import RestaurantService from '@/services/restaurant';
import { IBrandOrRestaurant } from '@/components/Dashboard/BrandRestaurantSwitcher';
import { VendorRole } from '@/constants/roleRestrictions';
import { useUserContext } from './user';

export type RestaurantProductTypes = 'qlub' | 'qsr' | 'softpos';

interface IRestaurantContext {
    restaurant: IRestaurant | null;
    setRestaurant: ((val: IRestaurant | null) => void) | any;
    selectedProductType: RestaurantProductTypes | null;
    setSelectedProductType: ((val: RestaurantProductTypes | null) => void) | any;
    selectedQrGroup: string | null;
    setSelectedQrGroup: ((val: string | null) => void) | any;
    selectedBrandOrRestaurant: IBrandOrRestaurant | null;
    setSelectedBrandOrRestaurant: ((val: IBrandOrRestaurant | null) => void) | any;
}

const getLSRestaurant = (): IRestaurant | null => {
    try {
        const item = localStorage.getItem(restaurantDataKey);
        if (item) {
            return JSON.parse(item);
        }
    } catch {
        return null;
    }
    return null;
};

const setLSRestaurant = (item: IRestaurant | null) => {
    localStorage.setItem(restaurantDataKey, JSON.stringify(item));
};

const RestaurantContext = createContext<IRestaurantContext>({
    restaurant: null,
    setRestaurant: null,
    selectedProductType: null,
    setSelectedProductType: null,
    selectedQrGroup: null,
    setSelectedQrGroup: null,
    selectedBrandOrRestaurant: null,
    setSelectedBrandOrRestaurant: null,
});

type Props = {
    children?: ReactNode;
};

export function RestaurantContextProvider({ children }: Props) {
    const { user } = useUserContext();
    const restaurantService = RestaurantService.getInstance();
    const [restaurant, setRestaurant] = useState<IRestaurant | null>(getLSRestaurant());
    const [selectedQrGroup, setSelectedQrGroup] = useState<string | null>(null);
    const [selectedProductType, setSelectedProductType] = useState<RestaurantProductTypes | null>(null);
    const [selectedBrandOrRestaurant, setSelectedBrandOrRestaurant] = useState<IBrandOrRestaurant | null>(null);

    const isBrandUser = [VendorRole.BrandAdmin, VendorRole.BrandAnalyst].includes(user?.userData?.role);

    useEffect(() => {
        if (selectedBrandOrRestaurant?.id) {
            localStorage.setItem(selectedBrandOrRestaurantKey, JSON.stringify(selectedBrandOrRestaurant));
        }
    }, [selectedBrandOrRestaurant?.id]);

    useEffect(() => {
        const token = localStorage.getItem(accessTokenKey);
        const restaurantId = localStorage.getItem(chosenRestaurantIdKey);
        const selectedBrandOrRestaurantLocal = localStorage.getItem(selectedBrandOrRestaurantKey);
        if (token && restaurantId) {
            restaurantService
                .getRestaurant(restaurantId)
                .then((data) => {
                    return {
                        ...data,
                        brand_verification_sent: restaurant?.brand_verification_sent,
                    };
                })
                .then((data) => {
                    setRestaurant(data);
                    setLSRestaurant(data);
                })
                .catch((err) => console.log(err));
        }
        if (selectedBrandOrRestaurantLocal) {
            setSelectedBrandOrRestaurant(JSON.parse(selectedBrandOrRestaurantLocal));
        } else if (restaurant?.id && isBrandUser) {
            setSelectedBrandOrRestaurant({
                id: restaurant?.id,
                isBrand: false,
                name: restaurant?.name,
            });
        }
    }, [restaurant?.id]);

    const setRestaurantHandler = useCallback((val: IRestaurant | null) => {
        setRestaurant(val);
        setLSRestaurant(val);
    }, []);

    return (
        <RestaurantContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                restaurant,
                setRestaurant: setRestaurantHandler,
                selectedProductType,
                setSelectedProductType,
                selectedQrGroup,
                setSelectedQrGroup,
                selectedBrandOrRestaurant,
                setSelectedBrandOrRestaurant,
            }}
        >
            {children}
        </RestaurantContext.Provider>
    );
}

export const useRestaurantContext = () => useContext(RestaurantContext);
