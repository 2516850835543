import { ICommission } from '@/views/Payments';

export enum LoyaltyProgram {
    QLUB = 'qlub',
    COMO = 'como',
    ROTANA = 'rotana',
    TIKIT = 'tikit',
}

export interface ILoyaltyProgramConfig {
    displayName?: string;
    pointIdentifier?: string;
    isEarnEnabled: boolean;
    isBurnEnabled: boolean;
    earnPointConversionRate: number;
    burnPointConversionRate: number;
    pointExpiryDuration: number;
    minPointsPerTransaction: number;
    maxPointsPerTransaction: number;
    redeemableBillPercentage: number;
    minimumRedeemableBillPercentage?: number;
    showExpiryNotificationOnLanding?: boolean;
    logo?: string;
    useToggle?: boolean;
    onlyBurnEnabledInVenues?: boolean;
    burnPermittedScheduleText?: string;
    loginBanner?: string;
    isDiscountEnabled: boolean;
}

export interface IProgramCountry {
    id: string;
    country: string;
    programId: string;
    config: ILoyaltyProgramConfig;
    createdAt: string;
    updatedAt: string;
}

export interface IProgramRestaurant {
    id: string;
    restaurantID: string;
    restaurantUnique: string;
    country: string;
    programId: string;
    config: ILoyaltyProgramConfig;
    createdAt: string;
    updatedAt: string;
}

export interface IScheduleDate {
    enabled: boolean;
    times: {
        start: string;
        end: string;
    }[];
}

export interface ISchedule {
    monday: IScheduleDate;
    tuesday: IScheduleDate;
    wednesday: IScheduleDate;
    thursday: IScheduleDate;
    friday: IScheduleDate;
    saturday: IScheduleDate;
    sunday: IScheduleDate;
}

export enum DiscountType {
    BILL = 'BILL',
}

export enum DiscountValueType {
    PERCENTAGE = 'PERCENTAGE',
    FIXED = 'FIXED',
}

export enum DiscountTriggerType {
    GENERAL = 'GENERAL',
}

export interface IDiscountConfig {
    schedule: ISchedule;
}

export interface IDiscount {
    id: number;
    uniqueID: string;
    title: string;
    programID: string;
    type: DiscountType;
    valueType: DiscountValueType;
    triggerType: DiscountTriggerType;
    amount: number;
    config: IDiscountConfig;
    discountMinimumValue: number;
    discountMaximumValue: number;
    minimumBillAmount: number;
    maximumBillAmount: number;
    enabled: boolean;
}

export enum SubscriptionTypes {
    LOYALTY = 'LOYALTY',
}

export const SubscriptionTypeOptions = [
    {
        value: SubscriptionTypes.LOYALTY,
        option: 'Loyalty',
    },
];

export enum SubscriptionIntervals {
    ONCE = 'once',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export interface ISubscriptionSchedule {
    id: string;
    note: string;
    remainingActions: number;
    limitedActions: boolean;
    paused: boolean;
    numActions: number;
    numActionsMissedCatchupWindow: number;
    numActionsSkippedOverlap: number;
    nextActionTimes: number[];
    createdAt: number;
    lastUpdateAt: number;
}

export interface ISubscriptionSchema {
    subscriptionID: number;
    subscriptionName: string;
    subscriptionType: SubscriptionTypes;
    subscriptionInterval: SubscriptionIntervals;
    subscriptionAmount: number;
    createdAt: number;
    options: SubscriptionOptions;
}

export interface SubscriptionOptions {
    isActive: boolean;
    isEditable: boolean;
    countryCode: string;
    subscriptionFreeMonths: number;
    activateWelcomeDiscount: boolean;
}

export interface ILoyaltyProgram {
    id: string;
    name: string;
    type: string;
    config: ILoyaltyProgramConfig;
    formId: number;
    countries: IProgramCountry[];
    restaurants: IProgramRestaurant[];
    createdAt: string;
    updatedAt: string;
    discounts: IDiscount[];
    subscription: ISubscriptionSchema;
    programType: LoyaltyProgramType;
}

export enum LoyaltyProgramType {
    IN_EARN_BURN = 'internal-earn-burn',
    IN_SUB_DISCOUNT = 'internal-subscription-discount',
}

export interface ILoyaltyMember {
    id: string;
    memberID: string;
    name: string;
    email: string;
    phoneNumber: string;
    allowEmail: boolean;
    allowSMS: boolean;
    status: string;
    tier: string;
    createdOn: Date;
    accountBalance: IAccountBalance;
    pointsEarned: number;
    pointsSpent: number;
    subscriptionExpenses: number;
    discountSavings: number;
}

export interface IAccountBalance {
    monetary: number;
    nonMonetary: number;
}

export interface ILoyaltyTransaction {
    id: number;
    amount: number;
    ledgerID: number;
    title: string;
    debitAccountID: number;
    debitBalance: number;
    creditAccountID: number;
    creditBalance: number;
    details: {
        service: string;
        refDocID: string;
        funnel: string;
        type: ILoyaltyTransactionType;
        info: {
            amount: number;
            programID: string;
        };
        meta: {
            partyId: string;
            tableSessionID: number;
            restaurantName: string;
            expiresAt: string;
            discountType?: string;
        };
    };
    paymentRecordDetails: {
        id: number;
        status: string;
        billAmount: number;
        tipAmount: number;
        leftToPay: number;
        lastUpdatedAt: number;
        tableSessionID: number;
        paidAmount: number;
        customerCommission: number;
        extraInfo: {
            Commissions: ICommission[];
        };
        PGExtraInfo: {
            CardBrand: 'Visa';
        };
    };
    createdAt: number;
    documentCreatedAt: number;
}

export enum ILoyaltyTransactionType {
    EARN = 'loyalty-earn',
    BURN = 'loyalty-burn',
    MOVE = 'loyalty-move',
    DISCOUNT = 'loyalty-discount',
}
