export enum REPORT_DOMAIN {
    BRAND = 'BRAND',
    RESTAURANT = 'RESTAURANT',
}

export enum ATTACHMENT_TYPE {
    NONE = 'NONE',
    CSV = 'CSV',
    XLSX = 'XLSX',
}

export enum PERIOD {
    HOURLY = 'HOURLY',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export enum REPORT_TYPE {
    ORDER_VIEW = 'ORDER_VIEW',
    TRANSACTION_VIEW = 'TRANSACTION_VIEW',
    REVIEW_EXPORT = 'REVIEW_EXPORT',
    QSR_ORDER = 'QSR_ORDER',
}

export enum REPORT_TYPE_TEXT {
    ORDER_VIEW = 'Order',
    TRANSACTION_VIEW = 'Transaction',
    REVIEW_EXPORT = 'Review',
    QSR_ORDER = 'QSR Order',
}

export enum ATTACHMENT_DATE_RANGE {
    YESTERDAY = 'YESTERDAY',
    TODAY = 'TODAY',
    CURRENT_MONTH = 'MONTHLY',
    CURRENT_WEEK = 'WEEKLY',
}

export enum WEEK_DAYS {
    SUNDAY = '0',
    MONDAY = '1',
    TUESDAY = '2',
    WEDNESDAY = '3',
    THURSDAY = '4',
    FRIDAY = '5',
    SATURDAY = '6',
}

export function getWeekDaysbyKeyValue(value: string) {
    const indexOfS = Object.values(WEEK_DAYS).indexOf(value as unknown as WEEK_DAYS);
    const key = Object.keys(WEEK_DAYS)[indexOfS];
    return key;
}

export interface RestaurantReportPreferences {
    reportType: REPORT_TYPE;
    dateRange: ATTACHMENT_DATE_RANGE | string;
    period: PERIOD;
    attachmentType: ATTACHMENT_TYPE;
    cc: string[];
    reportTime?: { hour: string; minute: string; day?: WEEK_DAYS };
    scheduleName?: string;
    fileName?: string;
}
export interface BrandReportPreferences {
    reportType: REPORT_TYPE;
    period: PERIOD;
    attachmentType: ATTACHMENT_TYPE;
    day: WEEK_DAYS;
    restaurantIds: string[];
    reportTime?: { hour: string; minute: string; day?: WEEK_DAYS };
    modifiedTimeZoneEnabled: boolean;
    scheduleName?: string;
    fileName?: string;
    cc: string[];
}

export interface IEditScheduledReports {
    modalOpen: boolean;
    handleModalClose: () => void;
    editData?: any;
}
export interface IScheduledReports {
    modalOpen: boolean;
    handleModalClose: () => void;
    editData?: any;
    refresh: () => void;
}

export const getReportType = (type: string) => {
    let attachType: REPORT_TYPE;
    switch (type) {
        case 'ORDER_VIEW':
            attachType = REPORT_TYPE.ORDER_VIEW;
            break;
        case 'TRANSACTION_VIEW':
            attachType = REPORT_TYPE.TRANSACTION_VIEW;
            break;
        case 'REVIEW_EXPORT':
            attachType = REPORT_TYPE.REVIEW_EXPORT;
            break;
        case 'QSR_ORDER':
            attachType = REPORT_TYPE.QSR_ORDER;
            break;
        default:
            attachType = REPORT_TYPE.ORDER_VIEW;
            break;
    }

    return attachType;
};

export const getAttachmentType = (type: string) => {
    let attachType: ATTACHMENT_TYPE;
    switch (type) {
        case 'NONE':
            attachType = ATTACHMENT_TYPE.NONE;
            break;
        case 'CSV':
            attachType = ATTACHMENT_TYPE.CSV;
            break;
        case 'XLSX':
            attachType = ATTACHMENT_TYPE.XLSX;
            break;
        default:
            attachType = ATTACHMENT_TYPE.NONE;
            break;
    }

    return attachType;
};

export const getDateRangeType = (type: string) => {
    let dateRange: ATTACHMENT_DATE_RANGE;
    switch (type) {
        case 'YESTERDAY':
            dateRange = ATTACHMENT_DATE_RANGE.YESTERDAY;
            break;
        case 'TODAY':
            dateRange = ATTACHMENT_DATE_RANGE.TODAY;
            break;
        case 'CURRENT_MONTH':
            dateRange = ATTACHMENT_DATE_RANGE.CURRENT_MONTH;
            break;
        default:
            dateRange = ATTACHMENT_DATE_RANGE.TODAY;
            break;
    }

    return dateRange;
};

export const getPeriodType = (type: string) => {
    let period: PERIOD;
    switch (type) {
        case 'HOURLY':
            period = PERIOD.HOURLY;
            break;
        case 'DAILY':
            period = PERIOD.DAILY;
            break;
        case 'WEEKLY':
            period = PERIOD.WEEKLY;
            break;
        case 'MONTHLY':
            period = PERIOD.MONTHLY;
            break;
        default:
            period = PERIOD.DAILY;
            break;
    }

    return period;
};
