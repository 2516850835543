import React from 'react';
import type { IRestaurant } from '@/views/OrdersTableView/types';

import { IPaymentTransaction } from '@/views/Orders/types';
import { PaymentRecordType } from '@/views/Payments';
import { useTranslation } from '@/hooks/translations';
import { useBillItemTranslation, useOrderItemTranslation } from './hooks/translations_hook';
import InvoiceItem from './InvoiceItem';
import { ColorEnum, IconEnum, StyleEnum, type IPaymentDetails } from '../../types';

export interface ILoyaltyInfoProps {
    vendor: IRestaurant | null;
    details: IPaymentDetails;
    lang: string | null;
    transaction?: IPaymentTransaction;
}

export const LoyaltyInfo = ({ details, vendor, lang, transaction }: ILoyaltyInfoProps) => {
    const { t } = useTranslation('common');

    const records = transaction?.paymentRecord?.filter((z) => z.type === PaymentRecordType.LoyaltyDiscount);

    if (!records) return null;

    return (
        <>
            {records.map((record) => (
                // in case we wanna add any kind of condistions or conditional props
                // on invoiceItem level we can use this hight order component
                <InvoiceItem
                    key={record.id}
                    val={{
                        value: record.paid_amount,
                        key: t('qlub plus discount'),
                        layout: {
                            icon: IconEnum.Discount,
                            key: 'loyalty-discount',
                            color: ColorEnum.Green,
                            style: StyleEnum.BodyMedium,
                        },
                    }}
                    currencyCode={vendor?.restaurant_country?.currency_code || ''}
                    currencySymbol={vendor?.restaurant_country?.currency_symbol || ''}
                    currencyPrecision={details.currencyPrecision || 0}
                    lang={lang}
                    useBillItemTranslation={useBillItemTranslation}
                    useOrderItemTranslation={useOrderItemTranslation}
                />
            ))}
        </>
    );
};
