import { memo, useMemo } from 'react';
import { IQsrOrder, OrderPosStatusEnum } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
}

const QsrOrderPosStatus = ({ order }: IProps) => {
    const { t } = useTranslation('common');

    const [style, text, visible] = useMemo(() => {
        switch (order.posStatus) {
            default:
            case OrderPosStatusEnum.Pending:
                return [styles.pending, t('pending'), false];
            case OrderPosStatusEnum.Failed:
                return [styles.declined, t('sync failed'), true];
            case OrderPosStatusEnum.Success:
                return [styles.declined, t('success'), false];
        }
    }, [order.posStatus]);

    if (!visible) {
        return null;
    }

    return (
        <div className={classNames(styles.main, style)}>
            <div className={styles.status}>{text}</div>
        </div>
    );
};

export default memo(QsrOrderPosStatus);
