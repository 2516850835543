import { useTranslation } from '@/hooks/translations';
import { queries, routes } from '@/constants/routes';
import {
    CreditCardRounded,
    DashboardRounded,
    FormatListBulletedRounded,
    QrCode2Rounded,
    RestaurantMenuRounded,
    SettingsRounded,
    TableBarRounded,
    ShoppingBasketRounded,
    Percent,
    RequestQuoteRounded,
    ReviewsOutlined,
    LocalOffer,
    QrCode2,
    Print,
} from '@mui/icons-material';
import { restrictRoles } from '@/constants/roleRestrictions';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useRestaurantContext } from '@/contexts/restaurant';
import { IRestaurant, RestaurantOrderMode } from '@/views/OrdersTableView/types';
import { ScheduledReportsIcon } from '@/icons/svgIcons';

export const hasQlubPosSection = (restaurant: IRestaurant | null) => {
    return (
        ((['qlub'].includes(restaurant?.posAccess?.posVendor.pos_vendor_name || '') ||
            restaurant?.order_config?.vendorQsrCardViewEnable) &&
            [RestaurantOrderMode.QSR, RestaurantOrderMode.Mixed].includes(
                restaurant?.order_mode || RestaurantOrderMode.Default,
            )) ||
        restaurant?.order_mode === RestaurantOrderMode.Mixed
    );
};

export const useSidebarRoutes = () => {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();

    const getRoutes = (user: any) => {
        const isQSR =
            user.posVendor === 'foodics' &&
            [RestaurantOrderMode.QSR, RestaurantOrderMode.Mixed].includes(
                restaurant?.order_mode || RestaurantOrderMode.Default,
            );

        return {
            general: [
                {
                    show: true,
                    text: t('Dashboard'),
                    link: routes.HOME,
                    Icon: DashboardRounded,
                    restrictRoles: restrictRoles.HOME,
                },
                {
                    show: true,
                    text: t('Orders'),
                    link: routes.ORDERS,
                    Icon: CreditCardRounded,
                    restrictRoles: restrictRoles.ORDERS_AND_TRANSACTIONS,
                },
                {
                    show: true,
                    text: t('Payments'),
                    link: routes.PAYMENTS,
                    Icon: ReceiptIcon,
                    restrictRoles: [...restrictRoles.PAYMENTS, ...restrictRoles.REFUND],
                },
                {
                    show: true,
                    text: t('Table View'),
                    link: routes.TABLE_VIEW,
                    Icon: TableBarRounded,
                    badgeAllow: true,
                    restrictRoles: restrictRoles.NOTIFICATION,
                },
                ...(restaurant?.config?.releaseToggle_reportsManagement
                    ? [
                          {
                              show: true,
                              text: t('Reports'),
                              link: routes.REPORTS,
                              Icon: ScheduledReportsIcon,
                              restrictRoles: restrictRoles.SCHEDULED_REPORTS,
                          },
                      ]
                    : []),
                ...(restaurant?.config?.vendor_enableReview
                    ? [
                          {
                              show: true,
                              text: t('Reviews'),
                              link: routes.REVIEWS,
                              Icon: ReviewsOutlined,
                              badgeAllow: true,
                              restrictRoles: restrictRoles.REVIEWS,
                          },
                      ]
                    : []),

                ...(user.posVendor === 'vpos'
                    ? [
                          {
                              show: true,
                              text: t('VPOS'),
                              link: routes.VPOS,
                              Icon: RestaurantIcon,
                              restrictRoles: restrictRoles.VPOS,
                          },
                      ]
                    : []),
                ...(hasQlubPosSection(restaurant)
                    ? [
                          {
                              show: true,
                              text: t('QSR Orders'),
                              link: routes.QSR_ORDERS,
                              Icon: ShoppingBasketRounded,
                              restrictRoles: restrictRoles.ORDERS_AND_TRANSACTIONS,
                          },
                      ]
                    : []),
                ...(restaurant?.config?.enable_payment_link
                    ? [
                          {
                              show: true,
                              text: t('Payment links'),
                              link: routes.PAYMENT_LINKS,
                              Icon: RequestQuoteRounded,
                              badgeAllow: true,
                              restrictRoles: restrictRoles.NOTIFICATION,
                          },
                          {
                              show: true,
                              text: t('Payment link'),
                              link: routes.PAYMENT_LINK_DETAILS,
                              Icon: RequestQuoteRounded,
                              badgeAllow: true,
                              restrictRoles: restrictRoles.NOTIFICATION,
                          },
                      ]
                    : []),
                {
                    show: true,
                    text: t('Loyalty'),
                    link: routes.LOYALTY,
                    Icon: RequestQuoteRounded,
                    badgeAllow: true,
                    restrictRoles: restrictRoles.NOTIFICATION,
                },
                {
                    show: true,
                    text: t('Loyalty'),
                    link: routes.LOYALTY_MEMBER_DETAILS,
                    Icon: RequestQuoteRounded,
                    badgeAllow: true,
                    restrictRoles: restrictRoles.NOTIFICATION,
                },
            ],
            settings: [
                {
                    text: t('Restaurant Details'),
                    link: routes.RESTAURANT_DETAILS,
                    Icon: FormatListBulletedRounded,
                    restrictRoles: restrictRoles.RESTAURANT_DETAILS,
                },
                ...([RestaurantOrderMode.QSR, RestaurantOrderMode.DigitalMenu, RestaurantOrderMode.Mixed].includes(
                    restaurant?.order_mode || RestaurantOrderMode.Default,
                )
                    ? [
                          {
                              text: t('Menus'),
                              link: routes.DIGITAL_MENU,
                              Icon: RestaurantMenuRounded,
                              restrictRoles: restrictRoles.MENUS,
                          },
                          ...(restaurant?.config?.qsrTaxManagement
                              ? [
                                    {
                                        text: t('Tax'),
                                        link: routes.TAX,
                                        Icon: Percent,
                                        restrictRoles: restrictRoles.MENUS,
                                    },
                                ]
                              : []),
                          {
                              text: t('Tag'),
                              link: routes.TAG,
                              Icon: LocalOffer,
                              restrictRoles: restrictRoles.MENUS,
                          },
                          ...(restaurant?.order_config?.printerV2
                              ? [
                                    {
                                        text: t('Print History'),
                                        link: routes.PRINTER,
                                        Icon: Print,
                                        restrictRoles: restrictRoles.MENUS,
                                    },
                                ]
                              : []),
                          // {
                          //     text: t('Inventory'),
                          //     link: routes.DIGITAL_MENU,
                          //     Icon: RestaurantMenuRounded,
                          //     restrictRoles: restrictRoles.MENUS,
                          // },
                      ]
                    : []),
                {
                    text: t('QR Code'),
                    link: routes.QR_CODE,
                    Icon: QrCode2Rounded,
                    restrictRoles: restrictRoles.QR_CODE,
                },
                ...(isQSR
                    ? [
                          {
                              text: t('Integrate QSR Foodics'),
                              link: routes.QSR_FOODICS,
                              Icon: RestaurantIcon,
                              restrictRoles: restrictRoles.FOODICS,
                          },
                      ]
                    : []),
                ...(user.posVendor === 'foodics'
                    ? [
                          {
                              text: t('Integrate Foodics'),
                              link: routes.FOODICS,
                              Icon: RestaurantIcon,
                              restrictRoles: restrictRoles.FOODICS,
                          },
                      ]
                    : []),
                ...(user.posVendor === 'ls-k-series'
                    ? [
                          {
                              text: t('Integrate LS-K-Series'),
                              link: routes.LS_K_SERIES,
                              Icon: RestaurantIcon,
                              restrictRoles: restrictRoles.LS_K_SERIES,
                          },
                      ]
                    : []),
                ...(user.posVendor === 'ls-o-series'
                    ? [
                          {
                              text: t('Integrate LS-O-Series'),
                              link: routes.LS_O_SERIES,
                              Icon: RestaurantIcon,
                              restrictRoles: restrictRoles.LS_O_SERIES,
                          },
                      ]
                    : []),
                {
                    text: t('Linkit'),
                    link: routes.LINKIT,
                    Icon: QrCode2,
                    restrictRoles: restrictRoles.LINKIT,
                },
                {
                    text: t('Settings'),
                    link: routes.SETTINGS,
                    Icon: SettingsRounded,
                    restrictRoles: restrictRoles.PROFILE_SETTINGS,
                    query: 'page',
                    children: [
                        {
                            text: t('Profile Settings'),
                            link: queries.PROFILE_SETTINGS,
                            restrictRoles: restrictRoles.PROFILE_SETTINGS,
                        },
                        {
                            text: t('Password and Authentication'),
                            link: queries.PASSWORD_AND_AUTHENTICATION,
                            restrictRoles: restrictRoles.PROFILE_SETTINGS,
                        },
                        {
                            text: t('Notification Settings'),
                            link: queries.NOTIFICATION_SETTINGS,
                            restrictRoles: restrictRoles.PROFILE_SETTINGS,
                        },
                        {
                            text: t('Scheduled Reports'),
                            link: queries.SCHEDULED_REPORTS,
                            restrictRoles: restrictRoles.SCHEDULED_REPORTS,
                        },
                    ],
                },
                // {
                //   text: "User Management",
                //   link: "/",
                //   Icon: PersonOutlineRoundedIcon,
                // },
            ],
        };
    };

    return { getRoutes };
};
