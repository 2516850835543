import { memo, useMemo, useState } from 'react';
import { IQsrOrder } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import { Tooltip } from '@mui/material';
import { format, isSameDay, differenceInMinutes, isEqual } from 'date-fns';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
}

const QsrScheduledOrderStatus = ({ order }: IProps) => {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);

    const tooltipTitle = useMemo(() => {
        let { scheduledAt, pickupAt } = order;
        if (!scheduledAt || !pickupAt) {
            return null;
        }

        if (typeof scheduledAt === 'string') {
            scheduledAt = new Date(scheduledAt);
        }

        if (typeof pickupAt === 'string') {
            pickupAt = new Date(pickupAt);
        }

        if (isSameDay(scheduledAt, new Date())) {
            if (isEqual(scheduledAt, pickupAt)) {
                return t(`It is scheduled at {{time}}`, { time: format(scheduledAt, 'HH:mm') });
            }
            const minutes = differenceInMinutes(pickupAt, scheduledAt);
            return t(`Scheduled at {{time}} and can be picked up {{min}} minutes later`, {
                time: format(scheduledAt, 'HH:mm'),
                min: minutes,
            });
        }

        // If scheduled day is not today, include the date
        if (isEqual(scheduledAt, pickupAt)) {
            return t(`It is scheduled on {{{date}}} at {{time}}`, {
                date: format(scheduledAt, 'yyyy-MM-dd'),
                time: format(scheduledAt, 'HH:mm'),
            });
        }

        const minutes = differenceInMinutes(pickupAt, scheduledAt);
        return t(`Scheduled on {{{date}}} at {{{time}}} and can be picked up {{min}} minutes later`, {
            date: format(scheduledAt, 'yyyy-MM-dd'),
            time: format(scheduledAt, 'HH:mm'),
            min: minutes,
        });
    }, [order]);

    return (
        <div className={styles.main}>
            <Tooltip
                title={tooltipTitle}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <div
                    className={styles.status}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    {t('scheduled')}
                </div>
            </Tooltip>
        </div>
    );
};

export default memo(QsrScheduledOrderStatus);
